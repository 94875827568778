import "./App.css";
import "./assets/sass/global.scss";
import "./assets/sass/index.scss";
import "./assets/sass/category.scss";
import "./assets/sass/sidenav.scss";
import "./assets/sass/login.scss";
import "./assets/sass/profile.scss";
import "./assets/sass/footer.scss";
import "./assets/sass/slicksingle.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/sass/mobile.scss";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Footer from "./layout/Footer";
import Navbar from "./layout/Navbar/Navbar";

import { AuthProvider } from "./contexts/Auth.context";
import { GlobalProvider } from "./contexts/Global.context";
import { CartProvider } from "./contexts/Cart.context";
import { WishlistProvider } from "./contexts/Wishlist.context";

import SideNav from "./components/SideNav";
import GoLogin from "./components/modals/GoLogin.modal";

import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import CategoriesPage from "./pages/CategoriesPage";
import SearchPage from "./pages/SearchPage";
import ProductDetails from "./pages/ProductDetailsPage";
import CartPage from "./pages/CartPage";
import WishlistPage from "./pages/WishlistPage";
import RegisterPage from "./pages/RegisterPage";
import BusinessRegisterPage from "./pages/BusinessRegisterPage";
import ProfilePage from "./pages/ProfilePage";
import TermsPage from "./pages/InfoPages/TermsPage";
import PrivacyPage from "./pages/InfoPages/PrivacyPage";
import ReturnPage from "./pages/InfoPages/ReturnPage";
import AboutPage from "./pages/InfoPages/AboutPage";
import PersonalInfoPage from "./pages/ProfilePage/PersonalInfoPage";
import OrderPayPage from "./pages/OrderPayPage";
import OrderPayedCompletePage from "./pages/OrderPayedCompletePage";
import AddressesPage from "./pages/ProfilePage/AddressesPage";
import OrdersPage from "./pages/ProfilePage/OrdersPage";
import PaymentMethodsPage from "./pages/ProfilePage/PaymentMethodsPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";

function App() {
  return (
    <>
      <AuthProvider>
        <GlobalProvider>
          <CartProvider>
            <WishlistProvider>
              <BrowserRouter>
                <>
                  <Navbar />
                  <SideNav />
                  <GoLogin />
                  <Routes>
                    <Route index element={<HomePage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPasswordPage />}
                    />
                    <Route
                      path="/reset-password"
                      element={<ResetPasswordPage />}
                    />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route
                      path="/register-business"
                      element={<BusinessRegisterPage />}
                    />
                    <Route path="/cart" element={<CartPage />} />
                    <Route path="/order/:id/pay" element={<OrderPayPage />} />
                    <Route
                      path="/order/:id/complete"
                      element={<OrderPayedCompletePage />}
                    />
                    <Route path="profile" element={<ProfilePage />}>
                      <Route index path="info" element={<PersonalInfoPage />} />
                      <Route path="orders" element={<OrdersPage />} />
                      <Route path="addresses" element={<AddressesPage />} />
                      <Route
                        path="payment-methods"
                        element={<PaymentMethodsPage />}
                      />
                    </Route>

                    <Route path="/wishlist" element={<WishlistPage />} />
                    <Route path="/category/:id" element={<CategoriesPage />} />
                    <Route path="/search" element={<SearchPage />} />
                    <Route path="/product/:id" element={<ProductDetails />} />

                    <Route path="/terms-conditions" element={<TermsPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPage />} />
                    <Route path="/return-policy" element={<ReturnPage />} />
                    <Route path="/about-us" element={<AboutPage />} />
                  </Routes>
                  <Footer />
                </>
              </BrowserRouter>
            </WishlistProvider>
          </CartProvider>
          <ToastContainer />
        </GlobalProvider>
      </AuthProvider>
    </>
  );
}

export default App;
