import { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useAuth } from "../../contexts/Auth.context";
import authService from "../../services/Auth.service";
import FormHandler from "../../utils/Handlers";
import { setFormLoading } from "../../utils/UiHelpers";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle";

function RegisterPage() {
  const navigate = useNavigate();
  const { setUser, setToken } = useAuth();
  const [form, setForm] = useState({
    email: "",
    password: "",
    confirm_password: "",
    is_agreed: "",
    first_name: "",
    last_name: "",
  });
  const [error, setError] = useState("");
  const formHandler = new FormHandler(form, setForm);

  useEffect(() => {
    setError("");
  }, [form]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (form.password != form.confirm_password) {
      setError("Confirm password not match");
      return;
    }
    setFormLoading(e.target);
    let res = await authService.register(form);

    if (res && res.data) {
      if (res.data.success == true) {
        setUser(res.data.result.user);
        setToken(res.data.result.token);
        navigate("/");
      } else {
        setError(res.data.message);
      }
    } else {
      if (res.response && res.response.data && res.response.data.message) {
        const data = res.response.data;
        let error = data.message;

        if (data.errors) {
          Object.keys(data.errors).forEach((err) => {
            data.errors[err].forEach((str_er) => {
              error += "<br />" + str_er;
            });
          });
        }

        setError(error);
      } else {
        setError("Error");
      }
    }
    setFormLoading(e.target, false);
  };

  return (
    <>
      <div className="container">
        <br />
        <br />
        <PageTitle text="Create Account" />
        <div className="pb-5 pt-3 login-box m-auto">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <label className="xlabel">
                  First Name
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <input
                  autoFocus
                  required
                  name="first_name"
                  type="text"
                  className="xinput"
                  value={form.first_name}
                  onChange={formHandler.handleTextChange}
                />
              </div>

              <div className="col-md-6">
                <label className="xlabel">
                  Last Name
                  <span className="position-absolute translate-middle p-2 ms-2 icon">
                    *
                  </span>
                </label>
                <input
                  required
                  name="last_name"
                  type="text"
                  className="xinput"
                  value={form.last_name}
                  onChange={formHandler.handleTextChange}
                />
              </div>
            </div>

            <label className="xlabel">
              Email Address
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="email"
              type="email"
              className="xinput"
              value={form.email}
              onChange={formHandler.handleTextChange}
            />
            <label className="xlabel">
              Password
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="password"
              type="password"
              className="xinput"
              value={form.password}
              onChange={formHandler.handleTextChange}
            />
            <label className="xlabel">
              Confirm Password
              <span className="position-absolute translate-middle p-2 ms-2 icon">
                *
              </span>
            </label>
            <input
              required
              name="confirm_password"
              type="password"
              className="xinput"
              value={form.confirm_password}
              onChange={formHandler.handleTextChange}
            />
            <div className="mb-3 d-flex">
              <label className="form-check ">
                <input
                  required
                  name="is_agreed"
                  className="form-check-input"
                  type="checkbox"
                  checked={form.is_agreed}
                  onChange={formHandler.handleTextChange}
                />
                <span className="agree">
                  By creating an account, you agree to our
                  <Link to="/terms-conditions">
                    &nbsp;Terms & Conditions&nbsp;
                  </Link>
                  and
                  <Link to="/privacy-policy">&nbsp;Privacy Policy&nbsp;</Link>
                </span>
              </label>
            </div>
            <div className="text-center">
              <span className="text-danger text-center d-block fw-bold mb-3">
                {parse(error)}
              </span>
              <button disabled={!form.is_agreed} type="submit" className="xbtn">
                CREATE ACCOUNT
              </button>
              <br />
              <hr className="mt-5" />
              <Link to="/register-business" className="btn btn2 border-0 mt-2 font-bold">
                CREATE BUSINESS ACCOUNT
              </Link>
              <div className="new-account">
                Already have an account?
                <Link to="/login"> Sign In</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
